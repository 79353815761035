import React from "react";
import "./css/index.css";
const PrivacyPolicyComp = () => {
  return (
    <div className="text-info" style={{ maxHeight: "300px", overflow: "auto" }}>
      <h1>PRIVACY POLICY</h1>
      <div className="mt-3">
        <h1>Introduction</h1>
        <p>
          Thank you for reading this privacy policy (this “Policy”). Your
          privacy is important to us, and for us to better protect it, we
          provide this Policy explaining our online information practices and
          the choices you can make about the way we collect and use your
          information.
          <br />
          <br />
          By accessing the website and using “BITS NFT,” including uploading,
          minting, trading, transferring, and purchasing NFTs, as well playing
          games, you agree to how we use your information as described in this
          Policy. If you do not agree to this Policy, you may not use BITS NFT.
        </p>
      </div>

      <div className="mt-3">
        <h1>About Us</h1>
        <p>
          The words “we,” “us,” and “our” as used throughout this Policy refers
          to BITS NFT, Inc, a company with its business address at 300 W
          Clarendon Ave, Suite 240 Phoenix, AZ, United States 85013. We are
          responsible for the collection, use, protection, storage, and other
          related activities performed on your information concerning your use
          of BITS NFT. If you have any concerns, issues, questions, inquiries,
          queries, or feedback about how we handle your personal information,
          please use our contact us form or email us at{" "}
          <a href="">info@bitsnft.io</a>.
        </p>
      </div>

      <div className="mt-3">
        <h1>The Information We Collect and How</h1>
        <p>
          The information we collect when you use BITS NFT may be categorized as
          personal information or non-personal information. While personal
          information (such as your name, email address, and profile photo) is
          any information that can be used to identify you as an individual,
          non-personal information is the opposite. We may collect both types of
          information in 2 major ways. These include:
          <br />
          <br />
        </p>
        <h5>
          The information you provide:{" "}
          <span>
            You may willingly provide us with certain information (mostly
            personal) through the website. This includes, without limitation,
            when you:
          </span>
        </h5>

        <ul>
          <li>create a user account on the website.</li>
          <li>upload your media files to create and mint your NFTs.</li>
          <li>
            connect your payment method information (i.e., your wallet address
            from <a href="">Metamask</a> or any other supported wallet
            provider).
          </li>
          <li>use the contact us form to request support services.</li>
          <li>
            provide your email address in the newsletter form on the website.
          </li>
          <li>provide additional information to your profile. </li>
        </ul>

        <p>
          The information you may provide us may include your name, email
          address, date of birth, country, city, profile photo, phone number,
          and login credentials. Statutorily, you are not obligated to provide
          us with personal information; however, we may be unable to provide
          some portions of BITS NFT to you without access to your information.
        </p>
        <br />

        <h5>
          The information we collect automatically:{" "}
          <span>
            Like other websites and services, we use cookies and other tracking
            tools (described below) on the website, and our service providers
            may embed these technologies in their tools, too. We use cookies and
            other technologies to gather certain information about you
            automatically upon your access to the website or interaction with
            our content. The information obtained automatically may include
            information about your:
          </span>
        </h5>

        <ul>
          <li>
            device (including IP address, geolocation, and browser version).
          </li>

          <li>
            transaction, sale, and purchase history (including the price,
            amount, and users involved).
          </li>

          <li>
            behavior on the website and interaction with our content (including
            the features you click on, preferences, search terms, your inputs,
            and videos watched).
          </li>
        </ul>
      </div>

      <div className="mt-3">
        <h1>Cookies and Similar Technologies</h1>
        <p>
          Cookies are tiny pieces of information that are attached to your
          device browser upon your access to the website. We use them on the
          website for certain activities, including remembering users when they
          come back to the website, identifying user preferences and the
          features they interact with, monitoring the website for violations of
          our <a href="">Terms and Conditions</a> and applicable laws, and
          performing certain features and functions.
          <br />
          <br />
          In addition to cookies, we may use other technologies, including
          pixels, beacons, clear gifs, and tags on our content to enable us to
          monitor which user takes action on specific content. <br />
          <br />
          The cookies on the website are embedded in your browser directly by
          us. However, our third-party service providers may also embed cookies
          on your browser to enable them to perform their services (for example,
          Google embeds cookies in Google Analytics to enable them to track and
          gather analytics).
          <br />
          <br />
          While some cookies exist for a browsing session and expire when you
          close your browser (session cookies), others stay longer and may exist
          between minutes to years – depending on their set expiry dates
          (persistent cookies).
          <br />
          <br />
          You have a choice to reject or accept cookies. You can reject cookies
          by navigating to the privacy settings function of your browser or
          changing your preferences through the cookie pop-up banner. Please, be
          aware that some cookies are strictly necessary for the performance of
          the website, and if such cookies were to be rejected, they may alter
          your smooth use of the website.
        </p>
      </div>

      <div className="mt-3">
        <h1>Google Analytics and Other Tools</h1>
        <p>
          We use certain third-party APIs and tools, such as{" "}
          <a href="">Enjin</a>’s SDK and
          <a href="">Google Analytics</a> for analytics gathering. Enjin is a
          third-party service that helps us with developing tools, data storage,
          and other features that make creating NFTs possible.
          <br />
          <br />
          Google Analytics is a third-party tracking and analytic gathering tool
          from <a href="">Google Inc</a>., which we use to gather information
          about traffic and the behaviors and preferences of users on the
          website. Google Analytics mostly collects anonymous information, but
          this information may include your device’s IP address.
          <br />
          <br />
          The analytic information collected through these tools helps us to
          personalize BITS NFT, analyze usage trends, secure the website, and
          help us with adverts that improve BITS NFT.
          <br />
          <br />
          Like cookies, you can opt out of Google Analytics tracking by
          installing the Google Analytics Opt-out browser <a href="">
            add-on
          </a>{" "}
          on your browser. This add-on instructs the Google Analytics JavaScript
          (ga.js, analytics.js, and dc.js) running on websites to prohibit
          sending information to Google Analytics.
        </p>
      </div>

      <div className="mt-3">
        <h1>Why we Collect your Information</h1>
        <p>
          We do not collect the information unless we need it to provide BITS
          NFT and secure it. We may collect the information described above for
          the reasons described below:
        </p>
        <br />
        <ul>
          <li>
            <h5>
              Service provision:{" "}
              <span>
                We collect certain information, such as your registration
                details, to provide you with our services. This may include
                creating an account for you, enabling you to upload your media
                and mint NFTs, allowing transactions, and registering your NFTs
                on the blockchain.
              </span>
            </h5>
          </li>

          <li>
            <h5>
              Communication and support:{" "}
              <span>
                We collect contact information, such as your email address and
                phone number, to enable us to provide support services, notify
                you of updates to our services, send you newsletters (where you
                have opted in), respond to your questions and inquiries, verify
                your account, secure your account, and send other
                communications.
              </span>
            </h5>
          </li>

          <li>
            <h5>
              Payment processing:{" "}
              <span>
                For us to process your payments or pay out your earnings, we
                require you to connect your wallet to the website.
              </span>
            </h5>
          </li>

          <li>
            <h5>
              User verification:{" "}
              <span>
                We collect certain information to enable us to verify your
                ownership of any media submitted, prevent fraud or copyright
                infringement issues, or identify you as a human.
              </span>
            </h5>
          </li>

          <li>
            <h5>
              Fraud prevention:{" "}
              <span>
                We may collect information, such as your location and date of
                birth, to monitor violations of and enforce our Terms and
                Conditions, comply with applicable laws, and take actions where
                you have violated any law.
              </span>
            </h5>
          </li>
        </ul>
      </div>

      <div className="mt-3">
        <h1>Our Legal Basis </h1>
        <p>
          Under applicable laws, especially the General Data Regulation
          Protection (GDPR), we are required to only process your information on
          at least one or more legal or lawful basis.
          <br />
          <br />
          We rely mostly on your consent with the information you submit to us.
          This means that we will not collect and use such information unless
          you have shown that you want us to collect it (for example, where you
          provide your email address in the newsletter form). <br />
          <br />
          Aside from consent, we collect other information, such as your date of
          birth, location, and IP address to satisfy our legitimate interest,
          including monitoring the website for violations, securing BITS NFT,
          identifying users, preventing bots and other malicious activities,
          improving BITS NFT, and managing legal issues.
          <br />
          <br />
          In other circumstances, we collect personal information to fulfill our
          contractual obligation – to provide you with the service you have
          requested.
          <br />
          <br />
          Lastly, we collect certain information (for example, your transaction
          information) to comply with our legal obligation to keep records.
        </p>
      </div>

      <div className="mt-3">
        <h1>The Sharing of your Information</h1>
        <p>
          We do not directly share or transfer personal information about you to
          unauthorized persons, nor do we sell, rent, or engage in its sale for
          monetary consideration (as described under applicable privacy laws).
          However, your information may be available to third parties under the
          below circumstances.
        </p>{" "}
        <br />
        <ul>
          <li>
            <h5>
              Sharing with service providers for service provision:{" "}
              <span>
                We hire the services of certain third-party service providers to
                provide parts of BITS NFT on our behalf. For example, we use (i)
                <a href="">Enjin.io</a>’s API, which allows you to create,
                manage, and trade your NFTs, (i) <a href="">Zendesk</a> for
                customer support services and direct emailing, (iii) Metamask
                for connecting your wallet for transaction purposes, and (iv)
                other services. In a type of way, these service providers may
                have access to relevant personal information to enable them to
                provide their services. However, they are only permitted to use
                the information to provide the services we hire them for.
              </span>
            </h5>
          </li>

          <li>
            <h5>
              Sharing with entities in a business transfer event:{" "}
              <span>
                In the event that we restructure or enter a business transfer
                agreement, including a sale of asset(s), a merger, an
                acquisition, a consolidation, or a case of bankruptcy, we may be
                obligated to share proprietary information (including personal
                information) with the entities involved.
              </span>
            </h5>
          </li>

          <li>
            <h5>
              Information available to users you transact with:{" "}
              <span>
                If you transact with a user, they may be able to see certain
                information about you, including your name and any other alias
                in your media.
              </span>
            </h5>
          </li>

          <li>
            <h5>
              Information from your media available to other users:{" "}
              <span>
                If you list your NFTs on the marketplace, your name, photo, and
                any other personal information attached to your media may be
                visible to any user that navigates to your media area.
              </span>
            </h5>
          </li>

          <li>
            <h5>
              Sharing with law enforcement upon request:{" "}
              <span>
                We may share personal information if we are required to do so by
                applicable law, such as in response to a subpoena or court
                order, or to protect our rights, property, or safety or those of
                other users or third parties.
              </span>
            </h5>
          </li>

          <li>
            <h5>
              Wallet addresses available publicly:{" "}
              <span>
                Since your wallet address is publicly visible on the blockchain,
                it may be possible that anyone you transact with can see your
                transactions if they make a search using your wallet address.
              </span>
            </h5>
          </li>
        </ul>
      </div>

      <div className="mt-3">
        <h1>How we Secure your Information</h1>
        <p>
          We take the security of your personal information very seriously. We
          have implemented appropriate technical and organizational measures in
          protecting it from unauthorized access, use, disclosure, or
          destruction. These measures may include, without limitation, (i) using
          complete data protection features within our database, (ii) not
          storing your crypto wallets, which allows you to self-custody, (iii)
          not selling your information to third parties for them to advertise to
          you, (iv) not sharing your information except as described under this
          Policy, and (v) using secure storage solutions for your registration
          information.
          <br />
          <br />
          However, no method of transmission over the internet or method of
          electronic storage has been proven to be completely secure. Thus,
          while we strive to use commercially acceptable means to protect your
          information, we cannot guarantee its absolute security.
        </p>
      </div>

      <div className="mt-3">
        <h1>Our Data Retention Policy</h1>
        <p>
          Unless you request that we delete your registration information, your
          information will remain on our database for the duration of your use
          of BITS NFT. For the NFTs, media, and tokens minted, they are retained
          on the blockchain and cannot be changed. Please note that even if you
          request the deletion of your registration information, we will retain
          it on our database for 1 year after your request. This is needed for
          business continuity and backup purposes or for our obligation to
          retain records and to serve as evidence in the event of any future
          disputes.{" "}
        </p>
      </div>

      <div className="mt-3">
        <h1>The Global Transfer of your Information</h1>
        <p>
          Our servers are maintained in the United States. This means that we
          use the information we collect about you in accordance with the United
          States privacy laws and any additional laws that apply to your
          location. However, we may also share and transfer personal information
          with our service providers in their locations, which may follow
          privacy laws that are more flexible (or inadequate) compared to those
          in the United States and your location.
          <br />
          <br />
          Nevertheless, we care about your privacy and would not engage or hire
          service providers who practice inadequate privacy practices. Where
          your location law offers additional privacy protection, we will take
          those measures. This may include – for example – entering into data
          transfer agreements, including using Standard Contractual Clauses
          applicable to the GDPR.
        </p>
      </div>

      <div className="mt-3">
        <h1>Your Privacy Rights</h1>
        <p>
          With respect to the information we process about you on BITS NFT, you
          may have some or all of the rights below – subject to your location
          privacy laws.{" "}
        </p>
        <br />
        <ul>
          <li>
            <h5>
              Right to opt out of newsletters:{" "}
              <span>
                If you opt in to receive updates about news, events, and
                marketing about BITS NFT, you have the right to opt out at any
                time by using the “unsubscribe” button at the bottom of any
                email we send to your email address.{" "}
              </span>
            </h5>
          </li>
          <li>
            <h5>
              Right to edit or rectify any inaccurate information:{" "}
              <span>
                If you believe that any information you provide to us is
                inaccurate, you have the right to request that we rectify it.
                The “Edit Profile” and “Account Settings” functions on your
                profile and account settings area allow you to edit your
                registration information.
              </span>
            </h5>
          </li>
          <li>
            <h5>
              Right to request the deletion of your information:{" "}
              <span>
                You have the right to demand that we delete your personal
                information by reaching out to us at{" "}
                <span>info@bitsnft.io</span>.
              </span>
            </h5>
          </li>
          <li>
            <h5>
              Right to withdraw consent:{" "}
              <span>
                You have the right to withdraw your consent if we process your
                information based on consent (for example, you can disconnect
                your wallet from the website, deny us access to your device file
                manager or gallery, etc.).
              </span>
            </h5>
          </li>
          <br />
          <h5>
            Users under the GDPR law have the following additional rights:
          </h5>
          <li>
            <h5>
              Right to data portability:{" "}
              <span>
                You may request that we transfer the information we hold about
                you to another service provider without it affecting the
                usability of your information.{" "}
              </span>
            </h5>
          </li>
          <li>
            <h5>
              Right to restrict processing:{" "}
              <span>
                Where you want that we restrict the processing of your data for
                specific purposes, including if you do not want us to delete the
                data but still not use it, you may exercise this right. This
                right only applies where we process such information based on
                consent or contract performance.
              </span>
            </h5>
          </li>
          <li>
            <h5>
              Right to object to processing:{" "}
              <span>
                You may object to the processing of specific information about
                you (for example, you may demand that we stop using your
                information for direct marketing).
              </span>
            </h5>
          </li>
          <li>
            <h5>
              Right to reporting:{" "}
              <span>
                You may report us to any data protection authority in your
                jurisdiction if you think we are unlawfully processing your
                information.
              </span>
            </h5>
          </li>
          <li>
            <h5>
              <span>
                You may reach out to us or exercise the above rights via our
                email address at <a href="">info@bitsnft.io</a>.
              </span>
            </h5>
          </li>{" "}
          <br />
          <h5>Users in California have the following additional rights:</h5>
          <li>
            The right to request that we disclose the categories of information
            we have collected, used, and disclosed to third parties in the past
            12 calendar months.{" "}
          </li>
          <li>
            The right to opt out of the “sale” of your personal information.
            BITS NFT is not in the business of selling personal information for
            monetary consideration (as the term is described under the
            California Consumer Privacy Act (CCPA).{" "}
          </li>
          <li>
            The right to exercise any of the above rights without being
            discriminated against.
          </li>
          <li>
            Users in California may exercise the above rights by reaching out to
            us at <a href="">info@bitsnft.io</a>.
          </li>
          <li>
            In addition to the above rights, we are required to state if we
            respond to Do Not Track signals. The Do Not Track signal is a
            browser feature that, if activated, sends a signal to website owners
            to not track the user’s online activities. We do not currently
            respond to these signals due to the inconsistency among industry
            participants.
          </li>{" "}
          <br />
          <h5>
            Users in Virginia, Utah, Connecticut, Colorado, and Nevada have the
            right below:{" "}
          </h5>
          <p>
            The above-mentioned states grant their citizens the right to opt out
            of the sale of their information (as this term is defined by those
            laws). We do not engage in practices termed as the “sale of personal
            information for monetary considerations,” but users in these states
            may still reach out to us at <a href="">info@bitsnft.io</a> to opt
            out of any future sale of their personal information.
          </p>{" "}
          <br />
          <h5>Users in Brazil have the following additional rights:</h5>
          <p>
            If you are using BITS NFT from Brazil, the Lei Geral de Protecao de
            Dados (LGPD) provides you with additional rights, including:
          </p>
          <li>the right to confirm the existence of processing.</li>
          <li>
            the right to have your personal information anonymized upon request.
          </li>
          <li>
            the right to request that we transfer your information to another
            provider in a way that does not render it useless.
          </li>
          <li>
            the right to request that we inform you of the third parties we have
            shared your information with.
          </li>
          <p>
            Users can make any of these requests by reaching out to us at
            <a href="">info@bitsnft.io</a>.
          </p>
        </ul>
      </div>

      <div className="mt-3">
        <h1>Our Policy about Minors</h1>
        <p>
          We do not target BITS NFT at people under 18 years of age or knowingly
          solicit personal information from them. You may only use BITS NFT (if
          you are under 18 years of age) through the account supervision of at
          least a parent or legal guardian. We take steps in deleting accounts
          that belong to people under 18 years of age. If you believe that a
          minor has provided us with personal information, please report to us
          at <a href="">info@bitsnft.io</a> to have the information deleted.
        </p>
      </div>

      <div className="mt-3">
        <h1>Third-Party Services</h1>
        <p>
          Our website may contain links, adverts, and content from third
          parties. This Policy does not represent the privacy practices of any
          of these third-party links, adverts, and content. When you link to any
          third-party service via BITS NFT, you should review their privacy
          practices before submitting personal information to them.
        </p>
      </div>

      <div className="mt-3">
        <h1>Changes to this Policy</h1>
        <p>
          We may change our privacy practices from time to time due to changes
          in applicable laws or any portion of BITS NFT, and we encourage you to
          review this Policy periodically. When we make changes, we will post
          the revised portion on this page and update the "Effective Date" at
          the top of this Policy. If we make material changes to this Policy, we
          will provide you with additional notice (such as by email or by
          posting a notice on the support section of your dashboard). Your
          continued use of BITS NFT after any changes to this Policy confirms
          your consent to the changes made.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicyComp;
